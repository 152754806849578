'use strict'

const _ = require('lodash')

const GROUP_TYPES = {
    LEGACY_GAPS: 'LEGACY_GAPS',
    OTHER: 'OTHER',
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    PINNED: 'PINNED',
    SOAP: 'SOAP',
    STRUCTURAL: 'STRUCTURAL'
}

const Z_INDEX = {
    MIN: 50,
    MED: 51,
    MAX: 52
}

const isFixed = compLayout => compLayout && !!compLayout.fixedPosition
const isPinned = compLayout => isFixed(compLayout) && !!compLayout.docked

const createOtherGroup = components => ({type: GROUP_TYPES.OTHER, components})
const createPinnedGroup = (components, groupId) => ({type: GROUP_TYPES.PINNED, components, id: groupId})
const createSOAPGroup = (components, groupId) => ({type: GROUP_TYPES.SOAP, components, id: groupId})
const createStructuralGroup = components => ({type: GROUP_TYPES.STRUCTURAL, components})

const isPagesContainer = compId => compId === 'PAGES_CONTAINER'
const isPinnedBreakpoint = (compId, compLayout) => {
    if (isPagesContainer(compId)) {
        return true
    }

    return (compId === 'SITE_HEADER' || compId === 'SITE_FOOTER') && isFixed(compLayout)
}

const reorderDOMForA11y = (groupedComponents, groupedComponentsByType, childrenLayout) => {
    // initial DOM position of structural groups
    const siteHeaderInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.SITE_HEADER)
    const pagesContainerInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.PAGES_CONTAINER)
    const siteFooterInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.SITE_FOOTER)

    // map between structural groups and their initial DOM position
    const structuralIndexes = {
        [siteHeaderInd]: 'SITE_HEADER',
        [pagesContainerInd]: 'PAGES_CONTAINER',
        [siteFooterInd]: 'SITE_FOOTER'
    }
    const sortedStructuralIndexes = _.map(_.sortBy(_.keys(structuralIndexes)), Number)

    // pinned groups and fixed structural groups should get z-index according to their initial DOM order
    const zIndexMap = {
        [structuralIndexes[sortedStructuralIndexes[0]]]: Z_INDEX.MIN,
        [structuralIndexes[sortedStructuralIndexes[1]]]: Z_INDEX.MED,
        [structuralIndexes[sortedStructuralIndexes[2]]]: Z_INDEX.MAX
    }

    //reorder component's groups according to correct DOM order for A11y
    const reorderedGroupedComponents = []

    // Push group to reorderedGroupedComponents and add z-index property to it if needed
    const pushToReorderedGroupedComponents = (groupId, zIndex, arr) => {
        if (zIndex && arr) {
            arr[groupId].zIndex = zIndex
        }
        reorderedGroupedComponents.push(groupedComponentsByType[groupId])
        delete groupedComponentsByType[groupId]
    }

    // Insert received group and OTHER groups that should be behind it to reorderedGroupedComponents
    const insert = ({groupId, groupIndex, zIndex, shouldAddZindexToGroup = false}) => {
        groupIndex = groupIndex && groupIndex > -1 ? groupIndex : _.findIndex(groupedComponents, group => group === groupedComponentsByType[groupId])

        // Find all OTHER groups before received group and push them to reorderedGroupedComponents
        const otherContainersBeforeGroup = _.takeRightWhile(_.slice(groupedComponents, 0, groupIndex), group => _.get(group, 'type') === GROUP_TYPES.OTHER)
        _.forEach(otherContainersBeforeGroup, otherGroup => {
            const componentId = _.get(otherGroup, 'components[0]')
            if (componentId) {
                pushToReorderedGroupedComponents(componentId, zIndex, childrenLayout)
            }
        })

        // Push received group to reorderedGroupedComponents
        pushToReorderedGroupedComponents(groupId, shouldAddZindexToGroup && zIndex, groupedComponentsByType)
    }

    // Site Header
    if (groupedComponentsByType.pinnedBeforeSITE_HEADER) {
        insert({groupId: 'pinnedBeforeSITE_HEADER', zIndex: zIndexMap.SITE_HEADER, shouldAddZindexToGroup: true})
    }
    insert({groupId: 'SITE_HEADER', groupIndex: siteHeaderInd, zIndex: zIndexMap.SITE_HEADER})
    // SOSP
    if (groupedComponentsByType.SOSP_CONTAINER_CUSTOM_ID) {
        insert({groupId: 'SOSP_CONTAINER_CUSTOM_ID', zIndex: zIndexMap.SITE_HEADER})
    }
    // Pages Container and SOAP
    if (groupedComponentsByType.pinnedBeforePAGES_CONTAINER) {
        insert({groupId: 'pinnedBeforePAGES_CONTAINER', zIndex: zIndexMap.PAGES_CONTAINER, shouldAddZindexToGroup: true})
    }
    if (groupedComponentsByType.soapBeforePagesContainer) {
        insert({groupId: 'soapBeforePagesContainer', zIndex: zIndexMap.PAGES_CONTAINER})
    }
    insert({groupId: 'PAGES_CONTAINER', groupIndex: pagesContainerInd, zIndex: zIndexMap.PAGES_CONTAINER})
    if (groupedComponentsByType.soapAfterPagesContainer) {
        insert({groupId: 'soapAfterPagesContainer', zIndex: zIndexMap.PAGES_CONTAINER})
    }
    // Site Footer
    if (groupedComponentsByType.pinnedBeforeSITE_FOOTER) {
        insert({groupId: 'pinnedBeforeSITE_FOOTER', zIndex: zIndexMap.SITE_FOOTER, shouldAddZindexToGroup: true})
    }
    insert({groupId: 'SITE_FOOTER', groupIndex: siteFooterInd, zIndex: zIndexMap.SITE_FOOTER})
    // Back To Top Button
    if (groupedComponentsByType.BACK_TO_TOP_BUTTON) {
        insert({groupId: 'BACK_TO_TOP_BUTTON', zIndex: Z_INDEX.MAX, shouldAddZindexToGroup: true})
    }
    // Quick Action Bar
    if (groupedComponentsByType.QUICK_ACTION_BAR) {
        insert({groupId: 'QUICK_ACTION_BAR', zIndex: Z_INDEX.MAX, shouldAddZindexToGroup: true})
    }
    // Pinned Last
    if (groupedComponentsByType.pinnedAfter) {
        insert({groupId: 'pinnedAfter', zIndex: Z_INDEX.MAX, shouldAddZindexToGroup: true})
    }
    // Reminding Groups
    _.forEach(_.keys(groupedComponentsByType), key => {
        const componentId = groupedComponentsByType[key].components[0]
        childrenLayout[componentId].zIndex = Z_INDEX.MAX
        reorderedGroupedComponents.push(groupedComponentsByType[key])
    })

    //set z-index on SITE_HEADER if needed
    if (isFixed(childrenLayout.SITE_HEADER)) {
        childrenLayout.SITE_HEADER.zIndex = zIndexMap.SITE_HEADER
    } else if (siteHeaderInd > pagesContainerInd) {
        childrenLayout.SITE_HEADER.zIndex = Z_INDEX.MIN
    }

    //set z-index on SITE_FOOTER if needed
    if (isFixed(childrenLayout.SITE_FOOTER)) {
        childrenLayout.SITE_FOOTER.zIndex = zIndexMap.SITE_FOOTER
    }

    return reorderedGroupedComponents
}

const shouldGroupBeBehindPinnedAfter = (childrenIds, childrenIdsIndexes, groupChildId, pinnedAgg) => !!_.find(pinnedAgg, value => childrenIdsIndexes[value] > childrenIdsIndexes[groupChildId])

const findIndexOfPinnedAfterGroup = (groupedComponents, childrenIds, lastPinnedBreakpointId, pinnedAgg, childrenIdsIndexes) => {
    let pinnedAfterIndex = groupedComponents.length
    // check if there are OTHER groups which should be after (above) pinnedAfter group
    _.forEachRight(groupedComponents, (group, index) => {
        const groupChildId = group.components[0]
        // if the current group is the last fixed structural group then break the loop (pinnedAfter group should be always above last fixed structural group)
        if (group.type === GROUP_TYPES.STRUCTURAL && groupChildId === lastPinnedBreakpointId) {
            return false
        }

        // if group is not OTHER, continue to next iteration
        if (group.type !== GROUP_TYPES.OTHER) {
            return
        }

        const groupBehindPinnedAfter = shouldGroupBeBehindPinnedAfter(childrenIds, childrenIdsIndexes, groupChildId, pinnedAgg)
        // if the current OTHER group should be before pinnedAfter group there is no need to continue because all OTHER groups that are before current group will always be also before the pinnedAfter group
        if (groupBehindPinnedAfter) {
            return false
        }
        pinnedAfterIndex = index
    })
    return pinnedAfterIndex
}

const getMasterPageChildrenGroups = (childrenIds, childrenLayout, isMobileView, shouldReorderDOM) => {
    const structuralIDs = isMobileView ?
        {
            SITE_HEADER: true,
            SITE_FOOTER: true,
            PAGES_CONTAINER: true,
            BACK_TO_TOP_BUTTON: true,
            SOSP_CONTAINER_CUSTOM_ID: true,
            QUICK_ACTION_BAR: true
        } :
        {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true}
    const isStructural = id => !!structuralIDs[id]

    let pinnedAgg = []
    let soapAgg = []
    let pageContainerIndex = 0
    let lastPinnedBreakpointId = ''

    const groupedComponentsByType = {}
    const childrenIdsIndexes = {}
    const groupedComponents = childrenIds.reduce((result, childId, index) => {
        childrenIdsIndexes[childId] = index

        if (isPinned(childrenLayout[childId]) && !isStructural(childId)) {
            pinnedAgg.push(childId)
            return result
        }

        if (isPinnedBreakpoint(childId, childrenLayout[childId])) {
            lastPinnedBreakpointId = childId

            if (!_.isEmpty(pinnedAgg)) {
                const pinnedGroup = createPinnedGroup(pinnedAgg, `pinnedBefore${childId}`)
                groupedComponentsByType[`pinnedBefore${childId}`] = pinnedGroup
                result.push(pinnedGroup)
                pinnedAgg = []
            }
        }

        if (isPagesContainer(childId)) {
            if (!_.isEmpty(soapAgg)) {
                const soapGroup = createSOAPGroup(soapAgg, 'soapBeforePagesContainer')
                groupedComponentsByType.soapBeforePagesContainer = soapGroup
                result.push(soapGroup)
                soapAgg = []
            }

            pageContainerIndex = result.length
        }

        if (isStructural(childId)) {
            const structuralGroup = createStructuralGroup([childId])
            groupedComponentsByType[childId] = structuralGroup
            result.push(structuralGroup)
        } else if (isFixed(childrenLayout[childId])) {
            const otherGroup = createOtherGroup([childId])
            groupedComponentsByType[childId] = otherGroup
            result.push(otherGroup)
        } else {
            soapAgg.push(childId)
        }

        return result
    }, [])

    if (!_.isEmpty(pinnedAgg)) {
        const pinnedAfterIndex = findIndexOfPinnedAfterGroup(groupedComponents, childrenIds, lastPinnedBreakpointId, pinnedAgg, childrenIdsIndexes)
        const pinnedAfter = createPinnedGroup(pinnedAgg, `pinnedAfter${lastPinnedBreakpointId}`)
        groupedComponentsByType.pinnedAfter = pinnedAfter
        groupedComponents.splice(pinnedAfterIndex, 0, pinnedAfter)
    }

    if (!_.isEmpty(soapAgg)) {
        const soapGroup = createSOAPGroup(soapAgg, 'soapAfterPagesContainer')
        groupedComponentsByType.soapAfterPagesContainer = soapGroup
        groupedComponents.splice(pageContainerIndex + 1, 0, soapGroup)
    }

    return shouldReorderDOM ? reorderDOMForA11y(groupedComponents, groupedComponentsByType, childrenLayout) : groupedComponents
}

module.exports = {
    GROUP_TYPES,
    Z_INDEX,
    getMasterPageChildrenGroups
}
